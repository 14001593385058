import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import StickyNav from '../../components/Navigation/StickyNav'
import gtm from '../../util/gtmEvent'
import GeneralForm from '../../components/Contact/forms/General'

const metaTitle =
  'Event Inquiries - Leading Digital Product Studio | Crowdlinker'

const metaDescription =
  'Reach out to Crowdlinker with all your event inquiries.'

export const formQuery = graphql`
  {
    contentfulContactForm(inquiryType: { eq: "event" }) {
      id
      title
      inquiryType
      contactName
      contactEmail
      contactImage {
        file {
          url
        }
        title
      }
      calendarLink
      id
    }
  }
`

export default class Contact extends React.Component {
  componentDidMount() {
    // Send event to GTM
    gtm('Contact Us', 'Event Inquiries', 'Contact Us', 'Form')
  }

  render() {
    const formDetails = this.props.data.contentfulContactForm

    return (
      <Layout page="contact">
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDescription} />
        </Helmet>

        <StickyNav />

        <GeneralForm formDetails={formDetails} />
      </Layout>
    )
  }
}
